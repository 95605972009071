import React, { useEffect, useState } from "react";
import { capitalizeFirstLetter, toCurrency } from "../../assets/js/utils/functions";
import CartContext from "../../contexts/cart-context";
import { useModals } from "../hooks/useModals";
import LazyImage from "../lazy-image";
import ClearSearch from "../clear-search";
import ProductDetailsModal from "./modals/product-details";
import { EmptyItems } from "./errors";
import { getDiscountPercentage, getProductOptionsLength } from "../../assets/js/utils/utils";
import classNames from "classnames";
import { ProductItemInterface } from "../../assets/interfaces";
import Portal from "../portal";
import useStoreItemsPagination from "../hooks/useStoreItemsManager";
import AddToCartButton from "./items/add-to-cart-btn";
import { DiscountBadge } from "./items/bages";

interface Props {
  productsLoading: boolean;
  items: { main: ProductItemInterface[]; featured: ProductItemInterface[] };
  search?: string;
  currentPage: number;
  pagData: { next_page: number | null; prev_page: number | null; total_pages: number | null };
  isLoading: boolean;
  goNext: () => void;
  resetPage: () => void;
  setSelectedItem: (item: ProductItemInterface) => void;
}

const StoreFrontItemsListView: React.FC<Props> = ({
  productsLoading,
  items,
  search,
  currentPage,
  pagData,
  goNext,
  setSelectedItem,
  isLoading,
  resetPage,
}) => {
  useStoreItemsPagination({ currentPage, pagData, isLoading, goNext });
  const { main, featured } = items;

  return (
    <div className="mt-10">
      <ClearSearch search={search} onClear={resetPage} />
      {featured && featured?.length > 0 && (
        <div className="pb-7.5 mb-7.5 sm:pb-10 sm:mb-10 lg:pb-12.5 lg:mb-12.5 border-b border-grey-border border-opacity-50">
          <h3 className="text-black heading-five font-bold mb-5 sm:mb-6.25 lg:mb-7.5 flex items-center">
            Featured Items
            {/* prettier-ignore */}
            <svg className="w-5 sm:w-6 ml-1 md:w-6.25 md:ml-1.25 lg:w-8 lg:ml-1.5 mt-1 text-cart-badge" viewBox="0 0 24 24" fill="none" >
              <path d="M19.8305 8.6998L15.3005 4.1698C14.3505 3.2198 13.0405 2.7098 11.7005 2.7798L6.70046 3.0198C4.70046 3.1098 3.11046 4.6998 3.01046 6.6898L2.77046 11.6898C2.71046 13.0298 3.21046 14.3398 4.16046 15.2898L8.69046 19.8198C10.5505 21.6798 13.5705 21.6798 15.4405 19.8198L19.8305 15.4298C21.7005 13.5798 21.7005 10.5598 19.8305 8.6998ZM9.50046 12.3798C7.91046 12.3798 6.62046 11.0898 6.62046 9.4998C6.62046 7.9098 7.91046 6.6198 9.50046 6.6198C11.0905 6.6198 12.3805 7.9098 12.3805 9.4998C12.3805 11.0898 11.0905 12.3798 9.50046 12.3798Z" fill="currentColor"/>
            </svg>
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-[repeat(auto-fit,minmax(400px,1fr))] md:gap-y-8.75 md:gap-x-10">
            {featured.map((item, index) => (
              <ProductItem index={index} key={item.id} toggleModal={(modal) => setSelectedItem(item)} item={item} />
            ))}
          </div>
        </div>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-[repeat(auto-fit,minmax(400px,1fr))] md:gap-y-8.75 md:gap-x-10">
        {items &&
          main.length > 0 &&
          main.map((item, index) => (
            <ProductItem index={index} key={item.id} toggleModal={(modal) => setSelectedItem(item)} item={item} />
          ))}
        {productsLoading && Array.from(Array(10).keys()).map((_, index) => <ProductItemLoader key={index} />)}
        {!productsLoading && main.length < 1 && <EmptyItems />}
      </div>
    </div>
  );
};

interface ProductItemProps {
  toggleModal: (modal: string) => void;
  index: number;
  item: ProductItemInterface;
}

const ProductItemLoader = () => {
  return (
    <article className="w-full flex items-stretch cursor-pointer md:max-w-[450px] border-b md:border-none border-grey-border border-opacity-50 py-3.75 md:py-0">
      <figure className="h-[70px] w-[70px] sm:h-20 sm:w-20 rounded-15 overflow-hidden bg-grey-loader animate-pulse"></figure>
      <div className="ml-5 flex flex-col mb-5 flex-1">
        <h5 className="w-40 h-3.5 bg-grey-loader animate-pulse rounded-15"></h5>
        <div className="flex w-full items-center justify-between">
          <span className="bg-grey-loader w-25 h-2.5 animate-pulse rounded-15"></span>
        </div>
      </div>
    </article>
  );
};

const ProductItem: React.FC<ProductItemProps> = ({ toggleModal, index, item }) => {
  const { addOrRemoveItem, isItemInCart, formatAsCurrency } = CartContext.useContainer();

  const handleItemButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    addOrRemoveItem(item);
  };

  return (
    <article
      key={index}
      className="w-full flex items-center cursor-pointer relative border-b last:!border-none md:border-none border-grey-border border-opacity-50 py-3.75 md:py-0"
      onClick={() => toggleModal("product_details")}
    >
      <figure className="h-[70px] w-[70px] sm:h-[80px] sm:w-[80px] rounded-[12.5px] sm:rounded-15 overflow-hidden relative flex-shrink-0">
        <LazyImage
          src={item.images[item?.thumbnail]}
          alt={item.name}
          loaderClasses="rounded-[12.5px] sm:rounded-15"
          className="h-full w-full object-cover relative rounded-[12.5px] sm:rounded-15"
        />
      </figure>
      <div className={`ml-5 flex flex-col flex-1 overflow-hidden`}>
        <div
          className={`w-full overflow-hidden flex items-center justify-between ${
            !item.available_to_purchase || !item.discount_price ? "!mb-4.5 sm:!mb-6" : "mb-1.5 sm:mb-2.5"
          }`}
        >
          <div className="flex items-center space-x-2.5">
            <h5
              className={`text-dark font-display font-semibold overflow-hidden text-1sm sm:text-[17px] max-w-full whitespace-nowrap overflow-ellipsis inline-block mt-1 sm:mt-0 ${
                item.available_to_purchase ? "text-opacity-100" : "text-opacity-50"
              }`}
            >
              {capitalizeFirstLetter(item.name)}
            </h5>
            {item.discount_price ? (
              <DiscountBadge percentage={getDiscountPercentage(item)} className="mt-0.5 w-max" />
            ) : null}
          </div>

          {!item.available_to_purchase && (
            <div className="bg-grey-fields-100 rounded-5 px-2 py-1 text-xxs text-accent-red-500 font-semibold absolute bottom-4.5 sm:bottom-3 right-0">
              Unavailable
            </div>
          )}
        </div>
        <div className={`flex w-full items-center justify-between`}>
          <span
            className={`flex flex-col text-1xs sm:text-sm text-grey-subtext ${
              item.available_to_purchase ? "text-opacity-100" : "text-opacity-70"
            }`}
          >
            {item.discount_price ? (
              <span
                className={`text-black font-semibold text-1xs sm:text-sm whitespace-nowrap ${
                  item.available_to_purchase ? "text-opacity-100" : "text-opacity-70"
                }`}
              >
                {formatAsCurrency(item.discount_price)}
              </span>
            ) : null}
            <span
              className={classNames(
                {
                  "text-opacity-100": item.available_to_purchase,
                  "text-opactity-70": !item.available_to_purchase,
                  "line-through text-xs": item.discount_price,
                  "text-black font-semibold text-1xs sm:text-sm": !item.discount_price,
                },
                " whitespace-nowrap"
              )}
            >
              {formatAsCurrency(item.price)}
            </span>
          </span>
        </div>
      </div>

      <div className="flex flex-col justify-between items-end h-full">
        <div>
          {item.available_to_purchase && item?.variants?.options?.length > 0 && (
            <div className="bg-grey-fields-100 rounded-5 px-2 pt-1 pb-0.5 text-xxs bottom-2 right-2 text-primary-500 ml-2 font-semibold inline-block">
              {getProductOptionsLength(item?.variants)} options
            </div>
          )}
        </div>
        {item.available_to_purchase && (
          <AddToCartButton isInCart={isItemInCart(item.id)} toggle={handleItemButtonClick} className="" />
          // <button
          //   className={`h-7.5 w-7.5 flex-shrink-0 sm:h-8.75 sm:w-8.75 rounded-full flex items-center justify-center shadow-accent ${
          //     isItemInCart(item.id) ? "bg-accent-green-500 text-white" : "bg-grey-fields-100 text-primary-500"
          //   }`}
          //   onClick={handleItemButtonClick}
          // >
          //   {isItemInCart(item.id) ? (
          //     // prettier-ignore
          //     <svg height="9" viewBox="0 0 11 8" fill="none" className="transform translate-y-[5%]">
          //         <path d="M10 1L3.8125 7L1 4.27273" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          //       </svg>
          //   ) : (
          //     // prettier-ignore
          //     <svg width="15" height="15" viewBox="0 0 14 14" fill="none">
          //         <path d="M7 2.91675V11.0834" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
          //         <path d="M2.91675 7H11.0834" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
          //       </svg>
          //   )}
          // </button>
        )}
      </div>
    </article>
  );
};

export default StoreFrontItemsListView;
